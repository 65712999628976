import React from 'react';
import logoWhite from 'images/logo-white.png'
import logoIcon from 'images/logo-icon.png'


function Header() {
  return (
    <div className="w-full py-5 bg-[#0A2862]">   
        <div className="w-full flex flex-col md:flex-row justify-between items-center container mx-auto">
            <a href="#">
                <img src={logoWhite} className="h-8" /> 
            </a>

            <div className="mx-auto md:mr-0 md:ml-auto mt-5 md:mt-0 flex flex-row space-x-3">
                <a href="/#" className="flex flex-row space-x-2 items-center bg-[#032874] p-3 rounded-md">
                    <img src={logoIcon} className="h-4" />
                    <span className="text-white">Balance</span>
                </a>
                <div className="p-3 bg-[#0667FF] rounded-md font-semibold flex items-center px-6 cursor-pointer">
                    <span>Connect Wallet</span>
                </div>
            </div>
        </div>

    </div>
  );
}

export default Header;
